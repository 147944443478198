import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios.js';
import { MatxLoading } from 'app/components';

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};
const BaseUrl = process.env.REACT_APP_API_URL_ROOT;

const isValidToken = async (accessToken) => {
  try {
    const response = await axios.post(BaseUrl + '/user/details');
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  loginAccessCode: () => Promise.resolve(),

  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const loginAccessCode = async (access_code) => {
    const response = await axios.post(BaseUrl + '/validate-access-code', {
      access_code,
    });

    const { accessToken } = response.data;
    console.log(response);
    if (response.status === 200) {
      setSession(accessToken);
      const user = {
        id: '',
        avatar: '',
        email: '',
        name: response.data.data.name,
        role: parseInt(response.data.data.role),
      };

      await dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
      return response;
    }
  };
  const login = async (username, password) => {
    const response = await axios.post(BaseUrl + '/login', {
      username,
      password,
    });

    const { accessToken } = await response.data;

    if (response.status === 200) {
      setSession(accessToken);
      const rslt = await isValidToken(accessToken);
      console.log(rslt);
      const user = {
        id: '',
        avatar: '',
        email: '',
        name: rslt.data.fullname,
        role: parseInt(rslt.data.role),
      };

      await dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });

      return response;
    }
  };

  const register = async (email, username, password) => {
    const response = await axios.post('/api/auth/register', {
      email,
      username,
      password,
    });

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };
  const BaseUrl = process.env.REACT_APP_API_URL_ROOT;
  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };
  const getDetails = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken) {
        const result = await isValidToken(accessToken);
        setSession(accessToken);

        if (accessToken && result.status === 200) {
          let rslt = result.data;

          return {
            payload: {
              isAuthenticated: true,
              user: {
                id: '',
                avatar: '',
                email: '',
                fullname: rslt.fullname,
                name: rslt.name,
                role: parseInt(rslt.role),
                branch: '',
              },
            },
          };
        }
      } else {
        return {
          payload: {
            isAuthenticated: false,
            user: [],
          },
        };
      }
    } catch (err) {
      /* if (localStorage.getItem('refreshToken')) {
        const state = refreshToken();
        getDetails();
        console.log(state);
      } */
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        setSession(accessToken);
        const result = await isValidToken(accessToken);

        if (accessToken && result.status === 200) {
          let rslt = result.data;

          await dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: true,
              user: {
                id: rslt.id,
                avatar: '',
                email: 'dodong',
                name: rslt.fullname,
                fullname: rslt.fullname,
                role: rslt.role,
              },
            },
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: false,
              // isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  if (state.isInitialised === false) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        loginAccessCode,

        logout,
        getDetails,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
