import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import { admin, staff, stockman, superadmin, admin_assistant, voter } from 'app/utils/constant';

const UserList = Loadable(lazy(() => import('./settings/UserList')));
const UserManagement = Loadable(lazy(() => import('./users/UserManagement')));
const ElectionList = Loadable(lazy(() => import('./election/ElectionList')));
const CandidateList = Loadable(lazy(() => import('./candidate/CandidateList')));
const VoterMasterList = Loadable(lazy(() => import('./users/VoterMasterList')));
const ElectionSummary = Loadable(lazy(() => import('./users/ElectionSummary')));
const Receipt = Loadable(lazy(() => import('./users/Receipt')));

const Vote = Loadable(lazy(() => import('./election/Vote')));

const ElectionResults = Loadable(lazy(() => import('../dashboard/ElectionResults')));
const NotFound = Loadable(lazy(() => import('./NotFound')));
const Forbidden = Loadable(lazy(() => import('./Forbidden')));
const systemRoutes = [
  {
    path: '/dashboard/default',
    element: <ElectionResults />,
    auth: [superadmin, admin],
  },
  {
    path: '/election-summary',
    element: <ElectionSummary />,
    auth: [superadmin, admin],
  },

  { path: '/user-management', element: <UserManagement />, auth: [superadmin] },
  { path: '/voters', element: <UserList />, auth: [superadmin, admin] },
  { path: '/ballot', element: <Vote />, auth: [voter] },
  { path: '/voting-receipt', element: <Receipt />, auth: [voter] },

  { path: '/election', element: <ElectionList />, auth: [superadmin] },
  { path: '/voters-masterlist', element: <VoterMasterList />, auth: [superadmin, admin, staff] },
  { path: '/candidates', element: <CandidateList />, auth: [superadmin, admin, staff] },

  {
    path: '/system/not-found',
    element: <NotFound />,
    auth: [voter, superadmin, admin, staff],
  },
  {
    path: '/system/forbidden',
    element: <Forbidden />,
    auth: [voter, superadmin, admin, staff],
  },
];

export default systemRoutes;
