export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const BRAND_NEW = 2;
export const NOT_BRAND_NEW = 3;
export const ALL_ITEM = 1;
export const CATEGORY = 3;
export const SERIAL = 2;
export const superadmin = 5;
export const admin = 2;
export const staff = 3;
export const stockman = 3;
export const manager = 4;
export const voter = 1;

export const admin_assistant = 5;

export const MAINBRANCH = { id: 1, name: 'Main' };
export const BRAND_OR_NAME = 1;
export const Roles = [
  { label: 'Admin', value: '2' },
  { label: 'Super Admin', value: '5' },
  { label: 'Staff', value: '3' },
];
export const VOTER = 1;
export const ADMIN = 2;
export const SUPER_ADMIN = 5;
export const STAFF = 3;
export const ACTIVE_VOTER = 1;

export const NON_VOTER = 2;
