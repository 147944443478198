import useAuth from 'app/hooks/useAuth';
import { flat } from 'app/utils/utils';
import { Navigate, useLocation } from 'react-router-dom';
import AllPages from '../routes';
import systemRoutes from 'app/views/systems/SystemRoutes';
/*const userHasPermission = (pathname, user, routes) => {
  if (!user) {
    return false;
  }
  const matched = routes.find((r) => r.path === pathname);
  const authenticated =
    matched && matched.auth && matched.auth.length ? matched.auth.includes(user.role) : true;
  return authenticated;
};
*/
const AuthGuard = ({ children }) => {
  let { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();

  const matched = systemRoutes.find((r) => r.path == pathname);
  let check = matched.auth.includes(parseInt(user?.role));
  if (isAuthenticated) {
    if (!check) {
      return <Navigate replace to="/system/forbidden" state={{ from: pathname }} />;
    }
    if (isAuthenticated && check) return <>{children}</>;
  } else {
    return (
      <>
        {isAuthenticated ? (
          children
        ) : (
          <Navigate replace to="/voting/login" state={{ from: pathname }} />
        )}
      </>
    );
  }
};

export default AuthGuard;
