import { voter, superadmin, admin, staff } from 'app/utils/constant';
export const navigations = [
  // {
  //  name: 'Dashboard',
  //  path: '/dashboard/default',
  //  icon: 'dashboard',
  // auth: [superadmin],
  //},
  // { label: 'PAGES', type: 'label' },

  {
    name: 'Election',
    auth: [superadmin],
    icon: 'poll',
    path: '/election',
  },
  {
    name: 'Election Voters Summary',
    auth: [superadmin],
    icon: 'assignment',
    path: '/election-summary',
  },
  {
    name: 'Partial Results',
    auth: [superadmin],
    icon: 'dashboard',
    path: '/dashboard/default',
  },
  {
    name: 'Candidate',
    auth: [superadmin, admin, staff],
    icon: 'recent_actors',
    path: '/candidates',
  },

  {
    name: 'Vote',
    auth: [voter],
    icon: 'event_available',
    path: '/ballot',
  },
  {
    name: 'Voting Receipt',
    auth: [voter],
    icon: 'print',
    path: '/voting-receipt',
  },
  {
    name: 'User Management',
    auth: [superadmin],

    icon: 'group',
    path: '/user-management',
  },

  {
    name: 'Voters',
    auth: [superadmin, staff],
    icon: 'local_library',
    children: [
      {
        name: 'Voters Entry',
        auth: [superadmin, admin],
        icon: 'person_add',
        path: '/voters',
      },
      {
        name: 'Voters MasterList',
        auth: [superadmin, admin, staff],
        icon: 'view_list',
        path: '/voters-masterlist',
      },
    ],
  },
  /*{
    name: 'Inventory',
    auth: [admin, admin_assistant, stockman],
    icon: 'assignment',
    children: [
      {
        name: 'Stock In',
        iconText: 'VT',
        path: '/inventory/stock-in',
        auth: [admin, stockman],
      },

      {
        name: 'Return Items',
        iconText: 'FP',
        path: '/inventory/return-items',
        auth: [admin, admin_assistant, stockman],
      },
      {
        name: 'Item List',
        iconText: 'FP',
        path: '/inventory/item-list',
        auth: [admin, stockman, admin_assistant],
      },
    ],
  },
  {
    name: 'Account Settings',
    //auth: [admin],
    icon: 'people_outline',
    children: [
      { name: 'Add User', iconText: 'VT', path: '/settings/add-user', auth: [admin] },
      { name: 'Add Branch', iconText: 'SU', path: '/settings/add-branch', auth: [admin] },
    ],
  },
  {
    name: 'Sales Report',
    path: '/sales-report/report',
    icon: 'poll',
    // auth: [admin, manager, admin_assistant, staff],
  },
*/
  //{ label: 'Components', type: 'label', auth: [1] },
];
