import useSettings from 'app/hooks/useSettings';

const MatxLogo = ({ className }) => {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  return <img src="/assets/images/ace-small.png" alt="" />;
};

export default MatxLogo;
